/* global window */
import React, { useLayoutEffect } from "react";
import { Box, Flex } from "@chakra-ui/layout";
import { motion, useSpring } from "framer-motion";
import PropTypes from "prop-types";
import Logo from "../images/logo.svg";
import { Link } from "gatsby";

export default function Footer({ animate, reff }) {
  const spring = useSpring(0, { damping: 30, stiffness: 60 });

  useLayoutEffect(() => {
    spring.onChange((latest) => {
      window.scrollTo(0, latest);
    });
  }, [spring]);

  function moveTo(to) {
    spring.set(window.pageYOffset, false);
    setTimeout(() => {
      spring.set(to);
    }, 50);
  }
  return (
    <footer ref={reff} id="footer">
      <Box p="20" h="60">
        <Flex
          h="full"
          experimental_spaceX="8"
          justifyContent="center"
          justifyItems="center"
        >
          <Link to="/impressum">Impressum</Link>
          <Link to="/datenschutz">Datenschutz</Link>
        </Flex>

        <Flex justify="center" overflow="hidden">
          <motion.img
            style={{
              width: "200px7",
              height: "200px",
            }}
            src={Logo}
            animate={animate}
            onClick={() => moveTo(0)}
          />
        </Flex>
      </Box>
    </footer>
  );
}

Footer.propTypes = {
  animate: PropTypes.func,
  reff: PropTypes.any,
};
