import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

export default function Header() {
  const { pathname } = useLocation();

  return (
    <header id="header">
      <Box>
        <Flex
          zIndex="900"
          justifyContent={["center", "center", "flex-end", "flex-end"]}
          mx={20}
          pt={5}
          fontSize={["lg", "lx", "xl", "2xl"]}
          experimental_spaceX="6"
          h="12"
        >
          {pathname === "/" ? null : (
            <Link to="/">
              <Text
                color="black"
                borderColor="blackAlpha.700"
                _hover={{ borderBottom: "4px" }}
              >
                Home
              </Text>
            </Link>
          )}
          <Link
            to="/#partner"
            style={{
              height: "38px",
            }}
          >
            <Text
              color="black"
              borderColor="blackAlpha.700"
              _hover={{ borderBottom: "4px" }}
            >
              Partner
            </Text>
          </Link>
          <Link to="/#contact">
            <Text
              color="black"
              borderColor="blackAlpha.700"
              _hover={{ borderBottom: "4px" }}
            >
              Kontakt
            </Text>
          </Link>
        </Flex>
      </Box>
    </header>
  );
}
